.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered-div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  margin:50px;
  padding:10px 0 100px 0;
  border: 1px solid #b5b4b4
}

.gray-div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F5F5F5;
  margin:50px;
  padding:10px 10px 100px 10px;
}

h1, h2 {
  text-align: center;
}

.centered-text {
  text-align:center;
}

body {
  /* background-color: #483EA8; */
  background-color:white;
  font-family: "DM Sans";
}

.upload {
  background-color: black;
  /* padding: 10px 200px 10px 200px; */
  border-radius: 4px;
  cursor: pointer;
  display:flex;
  min-width:600px;
  min-height:50px;
  justify-content: center;
  margin:10px 50px 10px 50px;
}


.upload-green {
  background-color: #1DC989;
  border-radius: 5px;
  cursor: pointer;
  width: 10%;
  justify-content: center;
  border-width:0px;
  display: flex;
  align-items: center;
}

.upload-green-initial {
  background-color: #1DC989;
  border-radius: 10px;
  cursor: pointer;
  min-width:200px;
  height:40px;
  justify-content: center;
  border-width:0px;
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.upload-initial {
  background-color: #ffffff;
  border-radius: 5px;
  /* cursor: pointer; */
  width:80vw;
  /* height:100vh; */
  justify-content: center;
  /* border-width:2px; */
  border: none;
  display: flex;
  align-items: center;
}


.upload-blue {
  background-color: #1F477D;
  border-radius: 5px;
  cursor: pointer;
  min-width:600px;
  min-height:50px;
  justify-content: center;
  border-width:0px;
  margin:10px 0px 10px 0px
}

.upload-blue-initial {
  background-color: #1F477D;
  border-radius: 10px;
  cursor: pointer;
  min-width:200px;
  height:40px;
  justify-content: center;
  border-width:0px;
  margin:10px 0px 10px 0px;
  align-items: center;
  display: flex;
}

.upload-grey {
  background-color: #ECECEC;
  border-radius: 5px;
  cursor: pointer;
  min-width:600px;
  min-height:50px;
  justify-content: center;
  border-width:0px;
  margin:10px 0px 10px 0px
}

.upload-green {
  background-color: #1DC989;
  border-radius: 5px;
  cursor: pointer;
  min-width:600px;
  min-height:50px;
  justify-content: center;
  border-width:0px;
  /* margin:10px 0px 10px 0px */
}

.upload:disabled {
  background-color:#ffffff;
  border-color: black;
  border-width:1px;
  cursor: initial;
}

.download {
  background-color: #483EA8;
  display:flex;
  min-width:900px;
  min-height:50px;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  margin:0 50px 0 50px;
}

.download:disabled {
  background-color:#ffffff;
  border-color: black;
  border-width:1px;
  cursor: initial;
}



.button-text {
  color: #FFFFFF;
  font-size: 18px;
  font-family: "DM Sans";
}

.button-text-initial {
  color: #1F477D;
  font-size: 28px;
  font-weight: 700;
  font-family: "DM Sans";
  text-align: center;
}


.button-text-white {
  font-size: 16px;
  color:white;
}

.upload:disabled .button-text, .download:disabled .button-text {
  color: black;
  font-size: 18px;
}

.button-container {
  display:flex;
  justify-content:center;
}

.button-select {
  /* Reset OS-specfic / browser-specific default style (see https://ishadeed.com/article/styling-the-good-old-button/#the-default-styles) */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #483EA8; /* to be replaced with your custom design */
  border: 1px solid currentColor; /* to be replaced with your custom design */
  /* Signify interactivity */
  border-radius: 4px; /* no physical button has a sharp corner; exact value to be replaced for your custom design */
  cursor: pointer;
  /* Match font style with the parent element */
  font: inherit;
  /* Make the button easy for touchscreen users to tap */
  min-height: 48px;
  min-width: 48px;
  margin: 0 0 20px 0;
}

.select-button-text {
  color:white;
}

.select-file-box {
  border-radius: 10px;
  border: 2px dotted #1F477D;
  background: #EBF0FF;
  width: 40vw;
  min-height: 50vh;
  /* padding:20px; */
  justify-content: center;
  cursor: pointer;
  display:flex;
  flex-direction: column;
  flex-grow: 1;
}

.supporting-text {
  color: #79797a;
  text-align:center;
}

.uploaded-docs {
  color: #79797a;
  font-size: 18px;
  margin:0 50px 0 50px;
}

.flex-container {
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.file-name {
  padding:4px;
  border: 1px solid green
}

.file-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 50px 0 50px;
}

.close-button {
  margin-left: 15px;
  cursor:pointer;
}


.menu-bar {
  display:flex;
  padding: 0 0 20px 20px;
  border: 2px solid black;
  border-width: 0 0 2px 0;
  justify-content: flex-start;
}

.menu-button {
  border-bottom: 5px solid black
}

.text {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.main-header {
  display:flex;
  padding: 0px 20px 0px 20px;
}


.header {
  font-size: 32px;
}


.main-panel {
  display:flex;
  flex-direction:column;
  /* margin:20px; */
  flex-grow:100;
  width:100%;
  border: 5;
  /* margin-top: 5px; 
  margin-left: 20px; */
  justify-content: top;
  align-items: left;
  max-height: 100vh;
  overflow-y:auto;
  /* margin:auto;
  max-height:800px; */
}






.main-body {
  display: flex;
  /* height:800px; */
  height:100vh;
  overflow:clip;
  width:100%;
}

.main-header {
  outline: 2px solid gray;
}

.left-panel {
  background-color: #1F477D;
  min-width:15%;
  justify-content: flex-start;
  align-items:left;
  display:flex;
  flex-direction:column;
}


.right-panel {
  background-color: #FAFBFF;
  width:40%;
  justify-content: flex-start;
  align-items:center;
  display:flex;
  flex-direction:column;
}

.right-panel-header {
  display:flex;
  justify-content: center;
  align-items: center;
  width:100vw;
}

.left-button-container {
  margin: 30px 0 0 0;
  justify-content: center;
  display:flex;
  flex-direction:column;
  align-items:center;
  width:100%;
}

.documents-text {
  text-align: center;
}

.file-select-button {
  display:flex;
  cursor: pointer;
  justify-content: flex-start;
}

.file-text {
  color:gray;
}

.left-files-container {
  margin: 30px;
  justify-content: center;
  display:flex;
  flex-direction:column;
}

.left-button {
  display:inline-block;
  width:80%;
  height: 60px;
  margin: 10px;
  cursor:pointer;
}

.select-button {
  display:inline-block;
  min-width:600px;
  height: 60px;
  margin: 10px;
  cursor:pointer;
}

.center-button {
  display:inline-block;
  height: 60px;
  margin: 10px;
  cursor:pointer;
}

.modal-container {
  display:flex;
  flex-direction:column;
  position: absolute;
  padding: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40vw;
  height: 50vh;
  background-color: white;
  /* border: 2px solid #000; */
  box-shadow: 24;
  border-radius:5px;
  align-items: center;
  justify-content: center;
}

.updated-modal-container {
  display:flex;
  flex-direction:column;
  position: absolute;
  padding:150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: white;
  box-shadow: 24;
  border-radius:5px;
}

.updated-modal-container h4 {
  text-align:center;
}

.generate-button {
  margin: 4px;
  min-width: 500px;
  min-height:50px;
  cursor: pointer;
  border-radius:5px;
  background-color:#1F477D;
  border-width:0px;

}

.generate-button p {
  color:white;
}

#react-doc-viewer {
  width:800px;
  height:600px;
}

.search-box {
  display:flex;
  margin:10px;
  align-items: center;
  justify-content: center;
}

.search-button {
  margin-left:10px;
}

.search-results {
  overflow-y:auto;
  max-width:80%;
}

.search-input {
  margin-left:13%
}

.search-form {
  justify-self: center;
}

.results-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display:block;
  max-height: 5.4em;
  line-height: 1.8em;

}

.loading-spinner-right {
  width: 32.5vw;
  min-height: 40vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.loading-spinner-middle {
  justify-content: center;
  margin:50px
}

.regenerate-box {
  display:flex;
  margin:10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction:column;
}

.select-input {
  width: 300px;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

.form-box {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  width:30vw;
  height: 40vh;
  border-radius: 20px;
}

.continue-button {
  background-color:#1DC989;
  padding: 12px;
  margin:20px;
  cursor: pointer;
  width:200px;
  border-radius:5px;
  border-width:0px;
}

.radio-button {
  margin:4px;
  padding:5px;
  cursor: pointer;
}

.radio-button.selected {
  background-color:#000;
  color:white;
}


.multiple-file-upload {
  border-radius: 10px;
  border: 1px solid #1F477D;
  background: #EBF0FF;
  min-width: 300px;
  max-height: 50px;
  margin-top: 0px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display:flex;
  flex-direction: column;
  flex-grow: 1;
  color: #1F477D;
  font-size: 18px;
  font-family: "DM Sans";
  padding:10px;
}

.question-text {
  text-align: center;
}

.heading-text {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
}


.document-box {
  display:flex;
  flex-direction: column;
}

.document-box-internal {
  margin:0px 0px 00px 0px;
  margin-right: 20px;
  cursor: pointer;
  border: solid 2px #EFF2F2;
  border-radius: 5px;
  padding:10px;
  flex-grow:100;
  width:100%
}

.document-date {
  color: #A3A29E;
}

.document-dates {
  display: flex;
  flex-direction: column;
}

.document-info {
  display: flex;
}

.separator {
  height:2px;
  color:#EFF2F2;
  background-color:#EFF2F2;
  border:none;
  width:100%;
  margin: 0;
}

.source {
  background-color: #EBF0FF;
  max-width: 200px;
  overflow:hidden; 
  text-overflow: ellipsis;
  border-radius: 5px;
  margin-top: -5px;
  /* padding-left: 10px; */
  padding: 5px;
  margin-right:10px;
  font-style: italic;
}


/* .sources-box {
  display:flex;
} */

.webviewContainer {
  display:flex;
  flex-grow:100;
}

.MyComponent {
  display:flex;
  flex-grow:100;
}

.webviewer {
  display:flex;
  flex-grow:100;
  margin-left: -20px;
}

.side-button {
  padding-left: 3px;
  padding-right: 3px;
  margin-top: 20px;
  margin-left:2px;
  margin-right:2px;
  cursor: pointer;
  width:40%;
  height: 40px;
  border-radius: 5px;
  border-width: 0px;
  display: flex;
  align-items: center;
}

.side-button.green {
  background-color: #1DC989;
}

.side-button.blue {
  background-color: #1F477D;
}

.side-button.grey {
  background-color: #EBF0FF;
}

.regenerate-button {
  background-color:#EBF0FF;
  padding: 12px;
  margin:20px;
  cursor: pointer;
  border-radius:5px;
  border-width:0px;  
  display:flex;
  align-items: center;
}



.blue-text {
  color:#1F477D
}

.blue-button-text {
  color:#1F477D;
  font-size:20px;
}

.green-text {
  color:#1DC989;
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 700;
  margin-top: 40px;
}


.document-container {
  display: flex;
  /* /* display:flex; */
  /* margin-right: 20px; */
  /* justify-content: center;
  align-items: center;
  margin-top: 10px; */ 
}

.logo {
  margin:20px;
  align-self: flex-start;
}

.source-box-container {
  max-height:500px;
  overflow-y:auto;
  }

.sources-box {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* padding:10px; */
  background-color: white;
  border-radius:5px;
  /* margin: 4px 0 4px 0; */
}

.sources-panel-box {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding:10px;
  background-color: white;
  border-radius:5px;
  margin: 4px 0 4px 0;
}

.right-panel-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 300px;
}

.chat-box {
  display: flex;
  height: 500px;
  flex-direction: column;
}


.cs-message__content {
  background-color: inherit !important;
}

.message-box-dark {
  background-color: #FFFFFF;
  border-radius:10px;
}

.message-box-light {
  background-color: #1DC989;
  border-radius:10px;
}

.cs-main-container{
  border: none !important;
  background-color: inherit !important;
}

.cs-chat-container{
  border: none !important;
  background-color: inherit !important;
}

.cs-button {
  color:#1DC989 !important
}

.cs-button:disabled {
  color:#FFFFFF !important
}

.cs-message-input {
  background-color: inherit !important;
}


.cs-message-input__content-editor {
  background-color: white !important;
  font-family: inherit !important;
}

.cs-message-list {
  background-color: inherit !important;
}


.cs-message-input__content-editor-wrapper {
  background-color: white !important;
}

.ps__thumb-y {
  background-color: gray !important;
}

.input-modal-container {
  display:flex;
  flex-direction:column;
  position: absolute;
  padding: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  /* border: 2px solid #000; */
  box-shadow: 24;
  border-radius:5px;
  align-items: center;
  justify-content: center;
}

.tab-button-container {
  display:flex;
  justify-content: center;

}

.document-box-container {
  max-width: 100%;
  /* margin-left: 20px; */
  max-height:80%;
}

.document-box-container.clicked {
  max-height:100%;
}

.initial-upload-container {
  margin-right:10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.save-button {
  display: flex;
  cursor: pointer;
  border-radius:5px;
  background-color: white;
  margin:4px;
  justify-content: center;
  align-items: center;
  border: 1px solid #1F477D;
}

.template-select {
  align-items: left;
  display: flex;
  flex-direction: column;
}

.template-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.template-button, .source-button {
  display: flex;
  cursor: pointer;
  border-radius:5px;
  background-color: #ffffff;
  margin:4px;
  justify-content: center;
  align-items: center;
  border: 4px solid #1F477D;
  min-width: 600px;
} 

.source-select {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.template-select {
  display:flex;
  flex-direction: column;
}

.literature-search {
  display: flex;
  flex-direction: column;
  align-items: center;
}

